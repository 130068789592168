import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
import { gameActions } from '../../actions';

// Components
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import GroupCard from '../../components/GroupCard';

class DebriefProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      filter_group: null,
      selected_serie: 'Completed',
    };

    // Bindings

    // Refs
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch, match, game } = this.props;
    if (match.params.id && !game?.data?._id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;
    const { filter_group } = this.state;
    const gameData = game.data;

    // For every group, count the number of completed applications
    // TODO (nocode): Make sure to remove duplicates
    const groupsCompleted = gameData?.groups?.map((g, gi) => {
      const groupUniqueItems = g?.items?.reduce((acc, item) => {
        const { activity, application } = item;
        const existingItem = acc.find(
          (i) => i.activity === activity && i.application === application
        );
        if (existingItem) {
          // If the item already exists, check if the current item is more recent
          if (new Date(item.created_at) > new Date(existingItem.created_at)) {
            // If the current item is more recent, replace the existing item
            acc.splice(acc.indexOf(existingItem), 1, item);
          }
        } else {
          // If the item doesn't exist, add it to the accumulator
          acc.push(item);
        }
        return acc;
      }, []);
      const decisions = groupUniqueItems?.filter(
        (i) => i.activity === 'decision'
      );

      const groupIdentifier = g?.name || `Team ${gi + 1}`;
      return {
        x: gi + 1,
        y: decisions?.length,
        _id: g._id,
        name: groupIdentifier,
      };
    });

    // For every group, count the number of incomplete applications
    // Incomplet applications are those that have items but no decision
    // TODO (nocode): Make sure to remove duplicates
    const groupsIncomplete = gameData?.groups?.map((g, gi) => {
      // Get the unique applications in the items array
      const uniqueApplications = [
        ...new Set(g?.items?.map((i) => i.application)),
      ];
      // Get the unique applications that don't have a decision
      const incompleteApplications = uniqueApplications?.filter((a) => {
        const hasDecision = g?.items?.find(
          (i) => i.application === a && i.activity === 'decision'
        );
        return !hasDecision;
      });
      const groupIdentifier = g?.name || `Team ${gi + 1}`;
      return {
        x: gi + 1,
        y: incompleteApplications?.length,
        _id: g._id,
        name: groupIdentifier,
      };
    });

    // For every group, calculate the number of Inventory applications
    // Inventory = total - completed - incomplete
    // TODO (nocode): Make sure to remove duplicates
    const groupsNonReviewed = gameData?.groups?.map((g, gi) => {
      const total = 49 - groupsCompleted[gi]?.y - groupsIncomplete[gi]?.y;
      const groupIdentifier = g?.name || `Team ${gi + 1}`;
      return { x: gi + 1, y: total, _id: g._id, name: groupIdentifier };
    });

    // Filter groups by their _id
    // use the filter_group state variable to filter the groups
    const filteredGroups = gameData?.groups?.filter((g) => {
      return g._id === this.state.filter_group;
    });

    // Calculate the max value for the y-axis
    const maxY = Math.max(
      ...groupsCompleted.map((g) => g.y),
      ...groupsIncomplete.map((g) => g.y),
      ...groupsNonReviewed.map((g) => g.y)
    );

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title fs-3'>
          Progress
          <small class='float-end'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <p className='text-center'>
            Click on the legend to display the count of completed, WIP, and
            applications in inventory by team. Click on a bar in the chart to
            display corresponding teams and performance details.
          </p>

          <div className='row'>
            <div className={`${filter_group ? 'col-7' : 'col-12'}`}>
              <HighchartsReact
                ref={this.chartRef}
                highcharts={Highcharts}
                options={{
                  title: { text: '' },
                  chart: { type: 'column', height: '500px' },
                  credits: { enabled: false },
                  legend: { enabled: true },
                  xAxis: {
                    title: { text: 'Team' },
                    labels: {
                      formatter() {
                        const pointIndex = this.pos - 1;
                        const point = groupsCompleted[pointIndex];
                        return point?.name;
                      },
                    },
                  },
                  yAxis: {
                    labels: { style: { fontSize: '14px' } },
                    allowDecimals: false,
                    min: 0,
                    max: maxY,
                    title: {
                      text: '',
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        `Team: ${this?.point?.name}<br>` +
                        `${this?.series?.name}: <b>${this.y}</b><br>` +
                        `Click for additional details.`
                      );
                    },
                  },
                  plotOptions: {
                    column: {
                      // stacking: 'normal',
                      // dataLabels: {
                      //   enabled: true,
                      //   //format: '{point.percentage:.1f} %',
                      //   formatter: function () {
                      //     if (this.percentage != 0)
                      //       return numeral(this.percentage).format('0') + '%';
                      //   },
                      // },
                    },
                    series: {
                      cursor: 'pointer',
                      events: {
                        legendItemClick: (e) => {
                          // Loop all series and hide them except the one clicked
                          // this.chartRef.current.chart.series.forEach((s) => {
                          //   if (s.name !== e.target.name) {
                          //     s.hide();
                          //   } else {
                          //     s.show();
                          //   }
                          // });
                          this.setState({ selected_serie: e.target.name });
                          return false;
                        },
                      },
                      point: {
                        events: {
                          click: (e) => {
                            // Set the group filter
                            this.setState(
                              {
                                filter_group: e.point?.options?._id,
                              },
                              () => {
                                // Reflow the chart
                                this.chartRef.current.chart.reflow();
                              }
                            );
                          },
                        },
                      },
                      // borderWidth: 5,
                      // borderColor: 'black',
                    },
                  },
                  series: [
                    {
                      name: 'Completed',
                      // color: 'rgba(223, 83, 83, .5)',
                      data: groupsCompleted,
                      visible: this.state.selected_serie === 'Completed',
                    },
                    {
                      name: 'WIP',
                      // color: 'rgba(80,180,50, .5)',
                      data: groupsIncomplete,
                      // hide this serie (initially)
                      visible: this.state.selected_serie === 'WIP',
                    },
                    {
                      name: 'Inventory',
                      // color: 'rgba(80,180,50, .5)',
                      data: groupsNonReviewed,
                      // hide this serie (initially)
                      visible: this.state.selected_serie === 'Inventory',
                    },
                  ],
                }}
              />
            </div>
            <div className={`${filter_group ? 'col-5' : 'd-none'}`}>
              <button
                className='btn btn-sm btn-link rounded-pill ps-0 mt-0 pt-0 mb-2'
                onClick={() => {
                  this.setState({ filter_group: null }, () => {
                    // Reflow the chart
                    this.chartRef.current.chart.reflow();
                  });
                }}>
                <small>Close Details</small>
              </button>
              {filteredGroups?.map((g) => {
                const gindex = gameData?.groups?.findIndex(
                  (group) => group._id === g._id
                );
                return (
                  <GroupCard key={`group-${g?._id}`} group={g} index={gindex} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefProgress);
