import { gameConstants } from '../constants';

export function game(state = { data: null, round: null }, action) {
  switch (action.type) {
    case gameConstants.SET_DATA:
      const d = action.data;
      if (d === null) return { data: null, round: null };
      else
        return {
          ...state,
          data: { ...state.data, ...d },
        };
    case gameConstants.SET_GAMES:
      return {
        games: action.data,
      };
    case gameConstants.SET_ROUND:
      const round = action.data;
      return {
        ...state,
        round,
      };
    case gameConstants.ADD_PLAYERS:
      const newpls = action.data;
      const totalPlayers = [...state.data.players, ...newpls];
      return {
        ...state,
        data: { ...state.data, ...{ players: totalPlayers } },
      };
    // SIM-SPECIFIC: Loan
    case gameConstants.UPSERT_GROUP_ITEM:
      const { group_id, item } = action.data;
      const newGroups = state.data.groups.map((g) => {
        // Find the group
        if (g._id === group_id) {
          // Find the item
          const itemFound = g.items.find((i) => i._id === item._id);
          // Update the item if found, else add it
          const groupItems = itemFound
            ? g.items.map((i) => {
                if (i._id === item._id) {
                  return item;
                } else {
                  return i;
                }
              })
            : [...g.items, item];
          // Return the new group
          return { ...g, items: groupItems };
        } else {
          return g;
        }
      });
      return {
        ...state,
        data: { ...state.data, ...{ groups: newGroups } },
      };
    default:
      return state;
  }
}
