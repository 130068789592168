import React from 'react';
import { Tooltip } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import csv_template from '../../assets/simcase_csv_template.csv';

import Header from '../../components/Header';
import CSVDrop from '../../components/CSVDrop';

// Helpers
import { setupSocket, socketEmit, socketOn } from '../../helpers/sockets';

// Actions
import { gameActions, modalActions } from '../../actions';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.game.data || {},
      changed: true,
      copied: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.submitParams = this.submitParams.bind(this);
    this.launch = this.launch.bind(this);
    this.readCSV = this.readCSV.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.autoAssignModal = this.autoAssignModal.bind(this);
    this.removePlayerModal = this.removePlayerModal.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { item } = this.state;

    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((res) => res.json())
      .then((data) => {
        setupSocket(data.io_domain, null, () => {
          socketEmit('enter-backend', { game_id: item._id });
        });
        socketOn('player', (data) => {
          // console.log({ data });
          dispatch(gameActions.fetchGames(item._id));
        });
      });
  }

  componentWillReceiveProps(newProps) {
    // Everytime REDUX updates the store
    // This hook will get executed and the state will get updated
    this.setState({ item: newProps.game.data });
  }

  handleChange(e) {
    const { name, value } = e.target;

    let theValue = value;
    if (name === 'players_text') theValue = value.replace(/\t/g, ', ');
    if (name === 'joineable') theValue = value === 'true' ? true : false;

    let item = { ...this.state.item };

    item[name] = theValue;
    item['changed'] = true;
    this.setState({ item }, () => {
      // Auto-save the game params (except for players_text)
      if (name !== 'players_text') this.submitParams({ [name]: theValue });
    });
  }

  handleChangeCheckbox(n, v, cb = null) {
    let item = { ...this.state.item };
    item[n] = v;
    item['changed'] = true;

    if (cb) {
      this.setState({ item }, () => cb(item));
    } else {
      this.setState({ item });
    }
  }

  // Handle the player's select group change
  // Uses gameActions.updateRegisteredPlayer to update the player's group
  handleGroupChange(email, e) {
    // console.log(email, e.target.value);

    const { item } = this.state;
    const { dispatch } = this.props;

    const gindex = parseInt(e.target.value);

    // Update the player's group
    dispatch(
      gameActions.updateRegisteredPlayer({
        _id: item._id,
        email,
        gindex,
      })
    );
  }

  submitParams(params = {}) {
    const { item } = this.state;
    const { dispatch } = this.props;

    // Set the status to saving
    this.setState({ item: { ...item, status: 'saving' } });

    // Update the game
    dispatch(gameActions.updateGameParams({ _id: item._id, ...params }));
  }

  submit() {
    const { item } = this.state;

    // Remove the following keys from the item object
    // players, groups
    const newItem = { ...item };
    delete newItem.players;
    delete newItem.groups;

    this.props.dispatch(gameActions.updateGame(newItem));
  }

  launch() {
    this.props.dispatch(gameActions.launchGame(this.state.item));
  }

  readCSV(content) {
    let item = { ...this.state.item };
    item.players_text = content;
    this.props.dispatch(gameActions.updateGame(item));
  }

  autoAssignModal() {
    const { dispatch } = this.props;
    const item = { ...this.state.item };
    dispatch(
      modalActions.open({
        title: 'Reset players per group',
        body: (
          <div>
            <p>
              This will automatically assign players to groups based on the
              selected number of <b>Players Per Group</b>.
            </p>
            <p>Any group customization will be overwritten.</p>
          </div>
        ),
        buttons: [
          <button
            key='btn-2'
            className='btn btn-outline-secondary'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            key='btn-1'
            className='btn btn-primary'
            onClick={() => {
              dispatch(gameActions.resetRegisteredPlayers({ _id: item._id }));
              dispatch(modalActions.close());
            }}>
            Auto-assign
          </button>,
        ],
      })
    );
  }

  removePlayerModal(e, user) {
    const { dispatch } = this.props;

    const username = `${user.name} ${user.lastname} ${user.email}`.trim();

    dispatch(
      modalActions.open({
        title: 'Remove Player',
        body: (
          <div>
            <p>
              Are you sure you want to remove <b>{username}</b> from this game?
            </p>
          </div>
        ),
        buttons: [
          <button
            key='btn-2'
            className='btn btn-outline-secondary'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            key='btn-1'
            className='btn btn-danger'
            onClick={() => {
              dispatch(
                gameActions.removeRegistered(this.state.item._id, [user?.email])
              );
              dispatch(modalActions.close());
            }}>
            Remove
          </button>,
        ],
      })
    );
  }

  render() {
    const { error } = this.props.game?.data || {};
    const { item } = this.state;

    // const okPPG = item.registered.length % item.ppg === 0 ? true : false;

    // Let's use item.pgg to calculate the number of potential groups
    const fullGroups = item.registered.length % item.ppg === 0 ? true : false;
    const numGroups =
      Math.ceil(item.registered.length / item.ppg) + (fullGroups ? 1 : 0);

    // Let's also collect the groupes that are currently specified in the registered players gindex
    const currentGroups = item.registered
      .filter((rp) => typeof rp.gindex !== 'undefined')
      .map((rp) => rp.gindex);
    const totalGroupsArray = [...Array(numGroups).keys()].map((g) => g + 1);
    const totalGroups = [...totalGroupsArray, ...currentGroups].sort();
    const uniqueGroupsIds = [...new Set(totalGroups)];

    // Calculate how many players are in this game
    const emptyPlayers = item.players.length === 0 ? true : false;

    // Sort registered players by group
    const registeredPlayers = [...item.registered].sort((a, b) => {
      if (a.gindex < b.gindex) return -1;
      if (a.gindex > b.gindex) return 1;
      return 0;
    });

    // Status validation
    if (item.status === 'launched')
      return <Redirect to={'/games/' + item._id + '/players'} />;

    return (
      <div className=''>
        <Header />

        <h2 className='mt-3 sec-title' style={{ lineHeight: 1.3 }}>
          Game Setup{' '}
          <small
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}
            className={
              item.status === 'saving'
                ? 'text-capitalize badge bg-pill bg-warning ms-2'
                : 'text-capitalize badge bg-pill bg-success ms-2'
            }>
            {item.status === 'saving' ? 'saving...' : item.status}
          </small>
          <div className='float-end' style={{ lineHeight: 1 }}>
            {error && (
              <small
                style={{
                  fontSize: '12px',
                  padding: '5px 8px',
                  verticalAlign: 'middle',
                }}
                className='badge bg-pill bg-white text-danger me-3'>
                {error}
              </small>
            )}
            {item.changed && (
              <small
                style={{
                  fontSize: '.85rem',
                  verticalAlign: 'middle',
                }}
                className='badge bg-pill text-secondary opacity-75 me-3'>
                Save changes before launch
              </small>
            )}
            {emptyPlayers && (
              <small
                style={{
                  fontSize: '.85rem',
                  verticalAlign: 'middle',
                }}
                className='badge bg-pill text-secondary opacity-75 me-3'>
                Add players and <b>save</b> before launch
              </small>
            )}

            <button
              className='btn btn-md btn-outline-primary'
              onClick={this.submit}
              disabled={item.status === 'saving' ? true : false}>
              Save
            </button>
            <button
              id='TooltipExample'
              className='btn btn-md btn-outline-success ms-3'
              onClick={this.launch}
              disabled={
                item.status === 'saving' || item.changed || emptyPlayers
              }>
              Launch
            </button>
            <Tooltip target='TooltipExample'>Hello world!</Tooltip>
          </div>
        </h2>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6'>
                <div className='form-group mb-3'>
                  <label>Game Name</label>
                  <div className='input-group input-group-lg'>
                    <input
                      name='name'
                      className='form-control'
                      placeholder='Enter game name'
                      disabled={item.status === 'saving' ? true : false}
                      value={item.name || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group mb-3'>
                  <label>Access Type</label>
                  <div className='input-group input-group-lg'>
                    <select
                      name='access_type'
                      className='form-control form-select'
                      value={item.access_type || ''}
                      onChange={this.handleChange}
                      disabled={item.status === 'saving' ? true : false}>
                      <option value='csv'>CSV</option>
                      <option value='link'>LINK</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group mb-0'>
                  <label>
                    Players Per Group{' '}
                    <button
                      onClick={this.autoAssignModal}
                      className='btn btn-sm btn-link p-0 ms-2 mb-1'>
                      (Reset)
                    </button>
                  </label>
                  <div className='input-group input-group-lg'>
                    <select
                      name='ppg'
                      className='form-control form-select'
                      value={item.ppg || ''}
                      onChange={this.handleChange}
                      disabled={item.status === 'saving' ? true : false}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5 - Default</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group mb-3'>
                  <label>Add Players Post-Launch</label>
                  <div className='input-group input-group-lg'>
                    <select
                      name='joineable'
                      className='form-control form-select'
                      value={item.joineable?.toString() || ''}
                      onChange={this.handleChange}
                      disabled={item.status === 'saving' ? true : false}>
                      <option value='true'>Yes - Default</option>
                      <option value='false'>No</option>
                    </select>
                  </div>
                </div>
              </div>

              {item.access_type === 'link' && (
                <div className='col-12 mt-4'>
                  <div className='card'>
                    <div className='card-body text-left pt-3 pb-3 pl-3 pr-3'>
                      <h5 className='mb-3'>Instructions</h5>
                      <ol>
                        <li className='mb-3'>
                          Begin by setting the session parameters above.
                        </li>
                        <li className='mb-3'>
                          Once session settings have been set, share the
                          following link with players:{' '}
                          {/* <code
                            className='d-block text-center my-3'
                            style={{ fontSize: '1.25rem' }}>
                            {item.short_url}
                          </code> */}
                          <small className='d-inline-block mt-2 mb-3 mx-auto'>
                            <span
                              className='badge bg-pill bg-dark'
                              style={{
                                fontSize: '1rem',
                                padding: '5px 0px 0px 0px',
                                verticalAlign: 'middle',
                              }}>
                              <span
                                style={{
                                  marginTop: '-5px',
                                  padding: '10px 10px',
                                }}
                                className='badge bg-secondary bg-pill'>
                                Sign-up
                              </span>
                              <code className='text-white ms-2 me-2'>
                                {item.short_url}
                              </code>
                              <CopyToClipboard
                                text={item.short_url}
                                onCopy={() => this.setState({ copied: true })}>
                                <span
                                  style={{
                                    marginTop: '-5px',
                                    padding: '10px 10px',
                                    cursor: 'pointer',
                                  }}
                                  className='badge bg-primary bg-pill'>
                                  {this.state.copied ? 'Copied' : 'Copy link'}
                                </span>
                              </CopyToClipboard>
                            </span>
                          </small>
                        </li>
                        <li className='mb-0'>
                          Once you have entered all participants, save the game
                          and launch it.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className='col-12 mt-2'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='joineable'
                    onChange={(e) => {
                      this.handleChangeCheckbox('joineable', e.target.checked);
                    }}
                    checked={item.joineable}
                    id='joineable'
                  />
                  <label className='form-check-label' htmlFor='joineable'>
                    Allow players to join the game <b>after</b> it has been
                    launched.
                  </label>
                </div>
              </div> */}

              {item.access_type === 'csv' && (
                <div className='col-12 mt-4'>
                  <div className='card'>
                    <div className='card-body text-left pt-3 pb-3 pl-3 pr-3'>
                      <h5 className='mb-3'>Instructions</h5>
                      <ol>
                        <li className='mb-3'>
                          Download the .csv template{' '}
                          <strong>
                            <a
                              href={csv_template}
                              target='_blank'
                              rel='noopener noreferrer'>
                              here
                            </a>
                          </strong>
                          . Fill-in details for all participants, then copy
                          &amp; paste to the text area at right.
                        </li>
                        <li className='mb-3'>
                          If you prefer, type each participant's email, first
                          name <b>(optional)</b>, and surname <b>(optional)</b>{' '}
                          in the text area at right. One per line, ie:
                          <br />
                          <code>john@email.com, John, Doe</code>
                        </li>
                        <li className='mb-0'>
                          Once you have entered all participants, save the game
                          and launch it.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-6'>
            <div className='form-group mb-3'>
              <div className='d-flex mb-2'>
                <label>
                  Registered Players{' '}
                  <span className='badge bg-warning bg-pill'>
                    {item.registered ? item.registered.length : 0}
                  </span>
                </label>
                <div className='ms-auto form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    onChange={(e) => {
                      this.handleChangeCheckbox(
                        'custom_group',
                        e.target.checked,
                        (newitem) => {
                          this.submitParams({
                            custom_group: newitem.custom_group,
                          });
                        }
                      );
                    }}
                    checked={item.custom_group}
                    disabled={item.status === 'saving' ? true : false}
                    id='toggle-player-code'
                  />
                  <label
                    className='form-check-label'
                    htmlFor='toggle-player-code'>
                    Allow Users To Input Team Code
                  </label>
                </div>
              </div>
              {/* { item.registered.length % item.ppg !== 0 && <small 
                            style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                            className="badge bg-pill bg-danger float-end">
                            This game needs {item.ppg} players per group. Add more players.
                        </small> } */}
              {item.access_type === 'csv' && (
                <CSVDrop onComplete={this.readCSV}>
                  <div className='input-group input-group-lg'>
                    <textarea
                      name='players_text'
                      className='form-control'
                      placeholder='email@example.com'
                      value={item.players_text || ''}
                      onChange={this.handleChange}
                      rows='15'
                      disabled={
                        item.status === 'saving' ? true : false
                      }></textarea>
                  </div>
                  <small>
                    <b>Tip</b>: You can also drag and drop your csv file here.
                  </small>
                </CSVDrop>
              )}

              {/* Total Groups: {numGroups} - {uniqueGroupsIds.join(', ')} */}
              {item.access_type === 'link' && (
                <ul className='list-group'>
                  {registeredPlayers.map((rp) => {
                    // Use the same bg color classes for players that belong to the same group
                    // Toggle between bg-light and bg-white
                    const bgClass =
                      rp.gindex % 2 === 0
                        ? 'bg-white'
                        : 'bg-primary bg-opacity-10';

                    const thisGroupPlayers = registeredPlayers.filter(
                      (rp2) => rp2.gindex === rp.gindex
                    );
                    const isGroupFull = thisGroupPlayers.length >= item.ppg;

                    // Use the border-left class to highlight players from groups that are full
                    // Count the number of players in the player group
                    // const borderClass = isGroupFull
                    //   ? 'd-inline-block border-start border-3 border-success ps-3 py-2'
                    //   : 'd-inline-block border-start border-3 border-warning ps-3 py-2';

                    return (
                      <li
                        key={`li-${rp.email}`}
                        className={`list-group-item d-flex align-items-center justify-content-between ${bgClass}`}>
                        <small>
                          {rp.email}{' '}
                          <button
                            className='btn btn-sm btn-link text-danger opacity-75x small ms-1'
                            onClick={(e) => this.removePlayerModal(e, rp)}>
                            <FontAwesomeIcon icon='times' />
                          </button>
                          <br />
                          <span className='text-muted'>
                            {rp?.name} {rp?.lastname}
                          </span>
                        </small>
                        <small
                          className={`small ms-auto me-3 fst-italic opacity-50 ${
                            isGroupFull ? 'text-success' : 'text-dark'
                          }`}>
                          <FontAwesomeIcon icon='user' className='me-1' />
                          {/* {isGroupFull ? 'Full' : 'Avaliable'} */}x{' '}
                          {thisGroupPlayers.length}
                        </small>
                        <span className='word-break'>
                          <select
                            key={`sel-${rp.email}-${rp.gindex}`}
                            className='form-select form-select-sm'
                            defaultValue={rp?.gindex}
                            onChange={(e) =>
                              this.handleGroupChange(rp.email, e)
                            }>
                            {/* <option value=''>NO TEAM</option> */}
                            {uniqueGroupsIds.map((g) => (
                              <option key={`opt-${g}`} value={g}>
                                Group {g}
                              </option>
                            ))}
                          </select>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameForm };
