import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

// Helpers
import { setupSocket, enterBackend, socketOn } from '../../helpers';

// Components
import { GameForm } from './form';
import { GameSetup } from './setup';
import { GamePlayers } from './players';
import { GameLeaderboard } from './leaderboard';

import DebriefOverview from './debrief';
import DebriefAccuracy from './debrief_accuracy';
import DebriefProgress from './debrief_progress';
import DebriefFlowRate from './debrief_flow_rate';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
    };

    // Bindings
    this.apiGetClientData = this.apiGetClientData.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    dispatch(gameActions.fetchGames(id));

    // Get the client data (io_domain)
    const clientData = this.apiGetClientData();
    clientData.then(({ io_domain }) => {
      // Conenct the socket
      if (io_domain) {
        setupSocket(io_domain, null, () => {
          enterBackend({ game_id: id }, (gameData) => {
            socketOn('group-item', ({ group_id, item }) => {
              console.log(`group-item`, group_id, item);
              dispatch(gameActions.upsertGroupItem(group_id, item));
            });
          });
        });
      }
    });
  }

  // Fetch the client data and return it
  apiGetClientData() {
    return fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((response) => response.json())
      .then((data) => data);
  }

  render() {
    const { game, match } = this.props;
    // const { item } = this.state;
    const SetupComponent =
      game && game.data && game.data.access_type === 'lti-hbp'
        ? GameSetup
        : GameForm;

    // Location validations
    const { pathname } = window.location;
    const isSaved = game?.data?.status === 'saved' ? true : false;

    // When saved
    const redirectWhenSaved = ['/players', '/debrief'];
    if (isSaved && redirectWhenSaved.some((s) => pathname.includes(s)))
      return <Redirect to={'/games/' + game?.data?._id} />;

    if (!game || game.loading) return <div>Loading...</div>;

    return (
      <div>
        {game && game.data && (
          <div>
            <Route exact path={match.path} component={SetupComponent} />
            <Route
              exact
              path={match.path + '/players'}
              component={GamePlayers}
            />

            <Route
              exact
              path={match.path + '/debrief/overview'}
              component={DebriefOverview}
            />

            <Route
              exact
              path={match.path + '/debrief/accuracy'}
              component={DebriefAccuracy}
            />

            <Route
              exact
              path={match.path + '/debrief/progress'}
              component={DebriefProgress}
            />

            <Route
              exact
              path={match.path + '/debrief/flow-rate'}
              component={DebriefFlowRate}
            />

            <Route
              exact
              path={match.path + '/debrief/leaderboard'}
              component={GameLeaderboard}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };
