import { gameConstants } from '../constants';
import { history } from '../helpers';
// import { enterGame as wsEnterGame } from '../helpers/sockets';

export const gameActions = {
  setData,
  getData,
  createGame,
  fetchGames,
  updateGame,
  updateGameParams,
  updateRegisteredPlayer,
  resetRegisteredPlayers,
  launchGame,
  softLaunch,
  addPlayers,
  removePlayers,
  removeRegistered,
  removeGroups,
  setGroups,
  newRound,
  setRound,
  // Sim-specific: Loan
  upsertGroupItem,
};

function setData(gameData) {
  return { type: gameConstants.SET_DATA, data: gameData };
}

function getData(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + id, (res) => {
      console.log(res);
      // dispatch({ type: gameConstants.SET_DATA, data: res });
    });
  };
}

// New methods
function fetchGames(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + (id ? id : ''))
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({ type: gameConstants.SET_DATA, data: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function createGame(data) {
  return (dispatch) => {
    console.log('createGame');
    fetch(process.env.REACT_APP_API_URL + '/games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function updateGame(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function updateGameParams(data) {
  return (dispatch) => {
    // dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/params', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        // Extract from res, only the values specified in data
        const newData = {};
        Object.keys(data).forEach((key) => {
          newData[key] = res[key];
        });

        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: newData }),
          500
        );
      });
  };
}

function updateRegisteredPlayer(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/registered',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function resetRegisteredPlayers(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/resetplayers',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function launchGame(data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/launch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function softLaunch(data) {
  return (dispatch) => {
    fetch(
      // process.env.REACT_APP_API_URL + '/games/' + data._id + '/softlaunch',
      process.env.REACT_APP_API_URL + '/games/' + data._id + '/launch',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(data._id)));
  };
}

function addPlayers(game_id, data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/players', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function removeRegistered(game_id, emails = []) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/registered', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emails),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
  };
}

function removePlayers(game_id, ids) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/players', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ids),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function removeGroups(game_id, group_ids) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/groups', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(group_ids),
    })
      .then((res) => res.json())
      .then((res) => fetchGames(game_id));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function setGroups(game_id, group_ids) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groups: group_ids }),
    })
      .then((res) => res.json())
      .then((res) => dispatch(fetchGames(game_id)));
    // .then( res => dispatch({ type: gameConstants.SET_DATA, data: res }) )
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

function newRound(game_id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/snapshot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function setRound(round_id) {
  return { type: gameConstants.SET_ROUND, data: round_id };
}

// Sim-specific: Loan
function upsertGroupItem(group_id, item) {
  return { type: gameConstants.UPSERT_GROUP_ITEM, data: { group_id, item } };
}
